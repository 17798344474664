<template>
  <section id="coverage-sumamry" class="py-100 features-section division">
    <div class="container">
      <!-- SECTION TITLE -->
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="section-title mb-70">
            <!-- Title -->
            <h2 class="s-40 w-700">You Can Cover A Wide Range Of Injuries For As Low As $18 Per Month</h2>
          </div>
        </div>
      </div>
      <!-- FEATURES-7 WRAPPER -->
      <div class="fbox-wrapper text-center">
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4">
          <!-- FEATURE BOX ITEM -->
          <div class="col"
            v-for="item in items" :key="item.key"
          >
            <div class="fbox-7 fb-1 r-12 wow fadeInUp">
              <!-- Icon -->
              <div class="fbox-ico ico-100">
                <div class="shape-ico color--theme">
                  <!-- Vector Icon -->
                  <img :src="`assets/images/emerge/${item.icon}`" :alt="item.title" />
                  
                </div>
              </div>
              <!-- End Icon -->
              <!-- Text -->
              <div class="fbox-txt">
                <h6 class="s-20 w-700">{{ item.title }}</h6>
                <p class="p-sm">
                  {{ item.text }}
                </p>
              </div>
            </div>
          </div>
          <!-- END FEATURE BOX ITEM -->
        </div>
        <!-- End row -->
      </div>
      <!-- END FEATURES-7 WRAPPER -->
    </div>
    <!-- End container -->
  </section>
</template>
<script lang="ts">
export default defineComponent({
  name: 'SectionsLandingsPickleballSection10',
  setup() {
    const items = [
      {
        key: 'fractures',
        icon: 'emerge-icon-fractures.png',
        title: 'Fractures',
        text: 'Up to $3,000'
      },
      {
        key: 'dislocations',
        icon: 'emerge-icon-dislocations.svg',
        title: 'Dislocations',
        text: 'Up to $3,000'
      },
      {
        key: 'surgery',
        icon: 'emerge-icon-surgery.svg',
        title: 'Surgery',
        text: 'Up to $2,000'
      },
      {
        key: 'hospital-stays',
        icon: 'emerge-icon-hospital-stays.svg',
        title: 'Hospital Stays',
        text: 'Up to $2,000'
      }
    ]

    return { items };
  }
})
</script>
<style lang="scss" scoped>
.fbox-ico-wrap {
  min-width: 50px;

  h1 {
    font-weight: 700;
  }
}
</style>
