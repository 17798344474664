<script setup>
useHead({
  bodyAttrs: {
    class: "navbar-dark navbar-fill scheme-emerge",
  },
});
const config = useRuntimeConfig()
const instanceLayoutName = config.public.instanceName
</script>
<template>
  <div v-if="instanceLayoutName === 'emerge'">
    <SectionsLandingsEmergeSectionBanner />
    <SectionsLandingsEmergeSectionForm />
    <SectionsLandingsEmergeSection2 />
    <SectionsLandingsEmergeSection3 />
    <SectionsLandingsEmergeSection4 />
    <SectionsLandingsEmergeSectionFooter/>
  </div>
  <div v-else-if="instanceLayoutName === 'hilltop'">
    <SectionsLandingsHilltopBanner />
    <SectionsLandingsHilltopSection2 />
    <SectionsLandingsHilltopSection4 />
    <SectionsLandingsHilltopSection3 />
  </div>
  <div v-else>
    <SectionsLandingsPickleballBanner />
    <SectionsLandingsPickleballSection2 />
    <SectionsLandingsPickleballSection4 />
    <SectionsLandingsPickleballSection10 />
    <SectionsLandingsPickleballSection3 />
    <SectionsLandingsPickleballSection6 />
    <SectionsLandingsPickleballFooterBanner />
  </div>
</template>