<template>
  <section
    class="bg--emerge-hero-6 bg--fixed py-100 ct-01 content-section division"
  >
    <div class="container my-15">
      <div class="row d-flex align-items-center">
        <!-- TEXT BLOCK -->
        <div class="col-md-12 order-last order-md-2 mt-10">
          <div
            class="txt-block left-column wow fadeInRight color--white text-center"
          >
            <h5 class="s-60 w-700">Get 25% More Benefits <br/>for Organized Sports Injuries</h5>

            <p>(Up to $1,000 per accident)</p>
            
            <v-card
              variant="tonal"
              class="mx-auto mt-10 d-none"
              elevation="16"
            >
              <v-card-text class="d-flex px-10">
                <template v-if="display.smAndDown">
                  <div class="d-flex flex-column">
                    <div class="align-center">
                      <h5 class="s-40">Proud Accidental Injury Insurance Partner</h5>
                    </div>
                    <div class="align-center my-5">
                      <v-img
                        :height="100"
                        alt="PickleBall"
                        src="/assets/images/EmergePickleBallLogo.png"
                      />
                    </div>
                    <div class="align-center my-5">
                      <v-img
                        :height="100"
                        alt="APP"
                        src="/assets/images/app-logo-white.png"
                      />
                    </div>
                  </div>
                </template>
                <template v-else>
                  <v-row>
                    <v-col>
                      <v-img
                        :height="100"
                        alt="PickleBall"
                        src="/assets/images/EmergePickleBallLogo.png"
                      />
                    </v-col>
                    <v-col md="6" class="align-center">
                      <h4 class="s-40">Proud Accidental Injury Insurance Partner</h4>
                    </v-col>
                    <v-col>
                      <v-img
                        :height="100"
                        alt="APP"
                        src="/assets/images/app-logo-white.png"
                      />
                    </v-col>
                  </v-row>
                </template>
              </v-card-text>
            </v-card>
            <hr class="divider my-15" />
            <h5 class="s-50 w-700">Proud Accidental Injury Insurance Partners</h5>
             <!-- BRANDS CAROUSEL -->
             <div class="row">
                <div class="col text-center">
                    <SlidersBrandLogos />
                </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End row -->
    </div>
    <!-- End container -->
  </section>
</template>

<script lang="ts">
import { useDisplay } from 'vuetify'
export default defineComponent({
  name: "ComponentsSectionsLandingsPickleballBanner",
  setup() {
    const display = ref(useDisplay())
    const goToQuote = () => {
      return navigateTo({ path: "/forms/quote/start" });
    };

    return { goToQuote, display };
  },
});
</script>
