<script lang="ts" setup>
import { FormsQuoteStart } from "#components";
import { useDisplay } from 'vuetify'

const { smAndDown, lgAndUp } = useDisplay()
const isMobile = smAndDown
const isLargeScreen = lgAndUp;
</script>
<template>
  <section class="bg--theme pattern-04 bg--fixed py-100 ct-01 content-section division">
    <div class="container">
      <v-row>
        <!-- IMAGE BLOCK -->
        <v-col cols="12" md="6" class="order-first order-md-2 pb-10" style="margin-bottom:50px">
          <v-card
            rounded="lg"
            elevation="12"
            class="fill-height sheet-with-background wow fadeInLeft pa-5 d-flex flex-column"
          >
            <v-card-text class="flex-grow-1">
              <div class="d-flex flex-column justify-center color--white">
                <h2 class="w-700 flex-grow-1" :class="{ 's-30': !isLargeScreen, 's-40': isLargeScreen }">
                 Your Insurance Has a Hole... We've Got You Covered
                </h2>
              </div>
            </v-card-text>
            <v-card-action>
              <v-card variant="tonal" class="mb-5 pa-5">
                <!-- Quote Icon -->
                <div class="review-ico ico-65">
                  <span class="flaticon-quote"></span>
                </div>
                <!-- Text -->
                <div class="review-txt w-600" :class="{ 's-25': !isLargeScreen, 's-30': isLargeScreen }">
                  <!-- Text -->
                  I thought my health insurance would pay for everything. Then I saw the deductible...
                  <span class="text-yellow-lighten-1">and the $5,000 bill!</span>
                </div>
                <!-- End Text -->
              </v-card>
            </v-card-action>
          </v-card>
        </v-col>

        <!-- FORM BLOCK -->
        <v-col cols="12" md="6" class="order-last order-md-2 pb-10">
          <div class="txt-block left-column wow fadeInRight">
            <FormsQuoteStart 
              form-title="Get Your Instant Quote Now"
              form-subtitle="As Low As $18 Per Month"
              :isSecondaryAction="true"

            />
          </div>
        </v-col>
        <!-- END TEXT BLOCK -->
      </v-row>
      <!-- End row -->
    </div>
    <!-- End container -->
  </section>
</template>
<style scoped>
.sheet-with-background {
  background-image: url("/assets/images/emerge/emerge-landing-left-quote-section-2.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
}
</style>
