<template>
  <section id="features-7" class="py-100 features-section division">
    <div class="container">
      <!-- SECTION TITLE -->
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="section-title mb-70">
            <!-- Title -->
            <h2 class="s-52 w-700">What Is Accidental Injury Insurance?</h2>
          </div>
        </div>
      </div>
      <!-- FEATURES-7 WRAPPER -->
      <div class="fbox-wrapper text-center">
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
          <!-- FEATURE BOX ITEM -->
          <div class="col"
            v-for="item in items" :key="item.key"
          >
            <div class="fbox-7 fb-1 r-12 wow fadeInUp">
              <!-- Icon -->
              <div class="fbox-ico ico-50">
                <div class="shape-ico color--theme">
                  <!-- Vector Icon -->
                  <span :class="item.icon"></span>
                  
                  <!-- Shape -->
                  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z"
                      transform="translate(100 100)"
                    />
                  </svg>
                </div>
              </div>
              <!-- End Icon -->
              <!-- Text -->
              <div class="fbox-txt">
                <h6 class="s-20 w-700">{{ item.title }}</h6>
                <p class="p-sm">
                  {{ item.text }}
                </p>
              </div>
            </div>
          </div>
          <!-- END FEATURE BOX ITEM -->
        </div>
        <!-- End row -->
      </div>
      <!-- END FEATURES-7 WRAPPER -->
    </div>
    <!-- End container -->
  </section>
</template>
<script lang="ts">
export default defineComponent({
  name: 'SectionsLandingsPickleballSection10',
  setup() {
    const items = [
      {
        key: '24-7-coverage',
        icon: 'flaticon-24-hours-1',
        title: '24/7 Coverage',
        text: 'For All Covered Accidents On-And-Off The Court'
      },
      {
        key: 'paid-directly-to-you',
        icon: 'flaticon-money',
        title: 'Paid Directly To You',
        text: 'Regardless Of Health Insurance'
      },
      {
        key: 'no-deductible',
        icon: 'flaticon-taxes',
        title: 'No Deductibles',
        text: 'Means More Money For You'
      }
    ]

    return { items };
  }
})
</script>
<style lang="scss" scoped>
.fbox-ico-wrap {
  min-width: 50px;

  h1 {
    font-weight: 700;
  }
}
</style>
