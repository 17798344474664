<template>
  <section id="hero-emerge" class="bg--scroll hero-section">
    <div class="container mt-15">
      <div class="row d-flex py-15">
        <!-- HERO TEXT -->
        <div class="col-md-12">
          <div class="hero-emerge-txt fadeInRight">
            <!-- Title -->
            <div class="text-center color--white">
              <v-card variant="tonal" class="mb-5">
                <v-card-text>
                  <h2 class="s-60 w-700">INJURIES <span class="w-500">Happen... <br/>Is Your</span> <span class="text-primary">Health Insurance</span> ENOUGH?</h2>
                </v-card-text>
              </v-card>
              
              <v-btn
                class="text--white"
                size="x-large"
                dark
                color="primary-darken-1"
                @click="goToQuote"
                data-test-id="get-quote-btn"
                >
                Review Our Benefits
              </v-btn>
              
              <!-- Text -->
             
              
            </div>
          </div>
        </div>
        <!-- END HERO TEXT -->
      </div>
      <!-- End row -->
    </div>
    <!-- End container -->
    <!-- WAVE SHAPE BOTTOM -->
    <!--<div class="wave-shape-bottom">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 290">
        <path
          fill-opacity="1"
          d="M0,256L120,250.7C240,245,480,235,720,224C960,213,1200,203,1320,197.3L1440,192L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
        ></path>
      </svg>
    </div>-->
  </section>
  <v-card variant="flat" color="secondary-lighten-1">
    <v-card-text class="text-center pa-10">
      <h5 class="s-25 w-700">Close the Gap on Out-Of-Pocket Expenses that Health Insurance Does NOT Cover!</h5>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
export default defineComponent({
  name: "ComponentsSectionsLandingsPickleballBanner",
  setup() {
    const goToQuote = () => {
      return navigateTo({ path: '/forms/quote/start' });
    }
    return { goToQuote }
  },
  mounted() {
    useStoreReset().resetAllStores()
  },  
})
</script>
