<template>
  <section id="why-emerge" class="bg--emerge-hero-2 py-100 division">
    <div class="container">
      <div class="justify-content-center">
        <div class="section-title mb-70 color--white">
          <h2 class="s-60 w-700">Why Choose Emerge?</h2>
        </div>
      </div>
      <v-row>
        <v-col cols="12" md="4">
          <div class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
            <img class="img-fluid" src="/assets/images/EmergeCashBenefits.png" alt="cash-benefits" />
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
            <img class="img-fluid" src="/assets/images/EmergeFastandEasy.png" alt="fast-and-easy" />
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
            <img class="img-fluid" src="/assets/images/EmergeAffordableProtection.png" alt="affordable-protection" />
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>
