<template>
  <section class="bg--hilltop-hero-02 bg--fixed py-100 ct-01 content-section division">
    <div class="container my-15">
        <div class="row d-flex align-items-center">
          <!-- TEXT BLOCK -->
          <div class="col-md-12 order-last order-md-2">
            <div class="txt-block left-column wow fadeInRight color--white text-center">
              <!-- Title -->
              <v-card variant="tonal" class="mb-5">
                <v-card-text class="text-shadow">
                  <h2 class="s-60"><strong class="text-red">Protection</strong> <span class="w-200">for the</span> <strong>Unexpected</strong></h2>
                  <h3>Hilltop Securities customers can now enjoy the peace of mind that comes with being covered with accidental injury insurance in the event an accident happens.</h3>
                </v-card-text>
              </v-card>
              <v-btn
                class="text--white mt-5"
                size="x-large"
                dark
                color="primary"
                @click="goToQuote"
                data-test-id="get-quote-btn"
                >
                Get Covered Today
              </v-btn>
            </div>
          </div>
            <!-- END TEXT BLOCK -->
        </div>
        <!-- End row -->
    </div>
    <!-- End container -->
  </section>
</template>
<script setup>
const goToQuote = () => {
    return navigateTo({ path: '/forms/quote/start' });
  }
</script>
<style scoped>
.text-shadow {
  text-shadow: #222222 1px 2px;
}

</style>
