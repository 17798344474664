<template>
    <section class="bg--emerge-hero-5 bg--fixed py-100 ct-01 content-section division">
        <div class="container my-15">
            <div class="row d-flex align-items-center">
                <!-- TEXT BLOCK -->
                <div class="col-md-12 order-last order-md-2 mt-10">
                    <div class="txt-block left-column wow fadeInRight color--white text-center">
                        <!-- Title -->
                        <h2 class="s-60"><span class="w-200">Play with</span> <strong>Confidence</strong> <br/><strong class="text-yellow-lighten-1">Protection</strong> <span class="w-200">for the</span> <strong>Unexpected</strong></h2>
                        
                    </div>
                </div>
                <!--<div class="video-preview wow fadeInUp mb-70">
                    !-- Play Icon --
                    <ElementsVideoModal
                      :link="'https://www.youtube.com/embed/NvGY9s-hb4o'"
                    >
                      <template v-slot:button>
                        <div
                          class="video-btn video-btn-xl bg--pink-400 ico-90 d-flex flex-column"
                        >
                          <div class="video-block-wrapper">
                            <span class="flaticon-play-button"></span>
                          </div>
                          <div class="mt-2 hidden-xs">Learn More...</div>
                        </div>
                      </template>
                    </ElementsVideoModal>
                  </div>-->
                <!-- END TEXT BLOCK -->
            </div>
            <!-- End row -->
        </div>
        <!-- End container -->
    </section>
</template>
