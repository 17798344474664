<template>
  <section id="features-11" class="pt-100 features-section division">
    <div class="container">
      <!-- SECTION TITLE -->
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-9">
          <div class="section-title mb-70">
            <!-- Title -->
            <h2 class="s-52 w-700">Get Covered Today!</h2>
            
          </div>
        </div>
        <!--<div class="row d-none">
          <div class="col">
            <div class="video-preview wow fadeInUp mb-70">
              <-- Play Icon --
              <ElementsVideoModal
                :link="'https://www.youtube.com/embed/NvGY9s-hb4o'"
              >
                <template v-slot:button>
                  <div
                    class="video-btn video-btn-xl bg--pink-400 ico-90 d-flex flex-column"
                  >
                    <div class="video-block-wrapper">
                      <span class="flaticon-play-button"></span>
                    </div>
                    <div class="mt-2 hidden-xs-only">Learn More...</div>
                  </div>
                </template>
              </ElementsVideoModal>

              <-- Preview Image --
              <img
                class="img-fluid"
                src="/assets/images/emerge-video-preview.jpg"
                alt="video-preview"
              />
            </div>
          </div>
        </div>-->
      </div>
    </div>
    <!-- End container -->
  </section>
</template>
