<template>
  <section class="py-100 ws-wrapper content-section">
    <div class="container">
      <v-card variant="tonal" rounded="lg" color="primary">
        <div class="bc-1-wrapper bg--fixed r-16">
          <div class="section-overlay">
            <div class="row d-flex align-items-center">
              <!-- TEXT BLOCK -->
              <div class="col-md-6 order-last order-md-2">
                <div class="txt-block left-column wow fadeInRight">
                  <!-- Section ID -->
                  <span class="section-id">Easy Process</span>
                  <!-- Title -->
                  <h2 class="s-46 w-700">How it works</h2>

                  <!-- BOX ITEMS  -->
                  <div 
                    v-for="item in items" :key="item.key"
                    class="fbox-11 fb-1 wow fadeInUp mb-5">
                    <!-- Icon -->
                    <div class="fbox-ico-wrap">
                      <div class="fbox-ico ico-50">
                        <div class="shape-ico color--emerge-500">
                          <!-- Vector Icon -->
                          <span><v-icon color="white" size="45">{{ item.icon }}</v-icon></span>
                          <!-- Shape -->
                          <svg
                            viewBox="0 0 200 200"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z"
                              transform="translate(100 100)"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <!-- End Icon -->
                    <!-- Text -->
                    <div class="fbox-txt color--black">
                      <h6 class="s-22 w-700">{{ item.title }}</h6>
                      <p>{{ item.text }}</p>
                    </div>
                  </div>
                 
                </div>
              </div>
              <!-- END TEXT BLOCK -->
              <!-- IMAGE BLOCK -->
              <div class="col-md-6 order-first order-md-2">
                <div class="img-block right-column wow fadeInLeft">
                  <img
                    class="img-fluid"
                    src="/assets/images/hero-emerge-image-02.png"
                    alt="hero"
                  />
                </div>
              </div>
            </div>
            <!-- End row -->
          </div>
          <!-- End section overlay -->
        </div>
      </v-card>
      <!-- End content wrapper -->
    </div>
    <!-- End container -->
  </section>
</template>
<script lang="ts">
export default defineComponent({
  name: 'SectionsLandingsHilltopSection6',
  setup() {
    const items = [
      {
        key: 'get-a-quote',
        icon: 'mdi-numeric-1',
        title: 'Get a quote',
        text: 'Answer a few simple questions, get your rates and adjust the coverage to fit you.'
      },
      {
        key: 'apply-online',
        icon: 'mdi-numeric-2',
        title: 'Apply online',
        text: 'Complete your application in minutes and get an instant decision.'
      },
      {
        key: 'gain-peace-of-mind',
        icon: 'mdi-numeric-3',
        title: 'Gain peace of mind',
        text: 'Know your family will have the money they need.'
      }
    ]
    const goToQuote = () => {
      return navigateTo({ path: '/forms/quote/start' });
    }

    return { items, goToQuote };
  }
})
</script>
<style lang="scss" scoped>
.fbox-ico-wrap {
  min-width: 50px;

  h1 {
    font-weight: 700;
  }
}
</style>