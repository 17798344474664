<template>
  <section id="hero-theme-emerge-mobile" class="bg--scroll hero-section" v-if="isMobile">
    <div class="container mt-15">

      <div class="row d-flex py-15 d-none">
        <!-- HERO TEXT -->
        <div class="col-md-12">
          <div class="hero-emerge-txt fadeInRight">
            <!-- Title -->
            <div class="text-center color--white">
              <v-card variant="tonal" class="mb-5">
                <v-card-text class="text-shadow">
                  <h2 class="s-40 w-500">A <span class="w-700 text-yellow-lighten-1">Broken Bone</span> Shouldn't <br/>Cost You An <span class="w-700 text-yellow-lighten-1">Arm</span> And A <span class="w-700 text-yellow-lighten-1">Leg</span></h2>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </div>
        <!-- END HERO TEXT -->
      </div>
      <!-- End row -->
    </div>
  </section>
  <section class="bg--scroll hero-section" style="margin-top: 120px;" v-else>
    <v-carousel
      show-arrows="hover"
      cycle
      hide-delimiter-background
      :touch="true">
      <v-carousel-item
        v-for="(item, i) in carouselItems"
        :key="`carousel-item-${i}`"
        :src="item.src"
        cover
      >
        <div class="text-center color--white d-flex fill-height justify-center align-center">
          <v-card variant="tonal" class="mb-5">
            <v-card-text class="text-shadow">
              <div v-html="item.title"></div>
            </v-card-text>
          </v-card>
        </div>
      </v-carousel-item>
    </v-carousel>
  </section>
  
  <v-card variant="flat" color="secondary-lighten-1">
    <v-card-text class="text-center" :class="{ 'pa-5': isMobile, 'pa-10': !isMobile }">
      <h5 class="s-46 w-700" v-if="!isMobile">Don't Let Your Deductible Break Your Budget</h5>
      <h5 class="s-35 w-700" v-else>Don't Let Your Deductible Break Your Budget</h5>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { useDisplay } from 'vuetify'
export default defineComponent({
  name: "ComponentsSectionsLandingsPickleballBanner",
  setup() {
    const goToQuote = () => {
      return navigateTo({ path: '/forms/quote/start' });
    }

    const { smAndDown, lgAndUp } = useDisplay()

    

    const carouselItems = ref([
      {
        src: '/assets/images/emerge/emerge-hero-1.jpg',
        title: `<h2 class="s-50 w-500">A <span class="w-700 text-yellow-lighten-1">Broken Bone</span> Shouldn't <br/>Cost You An <span class="w-700 text-yellow-lighten-1">Arm</span> And A <span class="w-700 text-yellow-lighten-1">Leg</span></h2>`,
      },
      {
        src: '/assets/images/emerge/emerge-hero-2.jpg',
        title: `<h2 class="s-50 w-500">When The Unexpected Strikes,<br/> <span class="w-700 text-yellow-lighten-1">Out-of-Pocket Costs</span> like <br/><span class="w-700 text-yellow-lighten-1">Deductibles & Copays</span> Can Add Up Rapidly </h2>`,
      },
      {
        src: '/assets/images/emerge/emerge-hero-3.jpg',
        title: `<h2 class="s-50 w-500">A <span class="w-700 text-yellow-lighten-1">Broken Bone</span> Shouldn't <br/>Cost You An <span class="w-700 text-yellow-lighten-1">Arm</span> And A <span class="w-700 text-yellow-lighten-1">Leg</span></h2>`,
      },
      {
        src: '/assets/images/emerge/emerge-hero-2.jpg',
        title: `<h2 class="s-50 w-500">When The Unexpected Strikes,<br/> <span class="w-700 text-yellow-lighten-1">Out-of-Pocket Costs</span> like <br/><span class="w-700 text-yellow-lighten-1">Deductibles & Copays</span> Can Add Up Rapidly </h2>`,
      },
    ])

    if(lgAndUp.value) {
      carouselItems.value = [
        {
          src: '/assets/images/emerge/emerge-hero-1.png',
          title: `<h2 class="s-60 w-500">A <span class="w-700 text-yellow-lighten-1">Broken Bone</span> Shouldn't <br/>Cost You An <span class="w-700 text-yellow-lighten-1">Arm</span> And A <span class="w-700 text-yellow-lighten-1">Leg</span></h2>`,
        },
        {
          src: '/assets/images/emerge/emerge-hero-2.png',
          title: `<h2 class="s-60 w-500">When The Unexpected Strikes,<br/> <span class="w-700 text-yellow-lighten-1">Out-of-Pocket Costs</span> like <br/><span class="w-700 text-yellow-lighten-1">Deductibles & Copays</span> Can Add Up Rapidly </h2>`,
        },
      ]
    }
    

    return { goToQuote, carouselItems, isMobile: smAndDown  }
  },
  mounted() {
    useStoreReset().resetAllStores()
  },  
})
</script>


<style scoped>
.text-shadow {
  text-shadow: #555555 1px 2px;
}
</style>
