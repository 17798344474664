<template>
  <section id="why-emerge" class="py-100 ws-wrapper content-section division pattern-02 bg--theme-secondary">
    <div class="container py-50">
      <div class="justify-content-center">
        <div class="section-title mb-70 color--white">
          <h2 class="s-60 w-700">Why Accident Insurance?</h2>
        </div>
      </div>
      <v-row>
        <v-col v-for="item in items" :key="item.key" cols="12" md="4">
          <div class="wow fadeInUp text-center color--white" style="visibility: visible; animation-name: fadeInUp;">
            <div class="d-flex flex-column align-items-center">
              <v-img src="/assets/images/hilltop/tick-blue.png" :alt="item.title" width="110px" :key="`img-${item.key}`" />
              <div style="max-width: 300px;">
                <h6 class="s-20 w-700">{{ item.title }}</h6>
                <p class="p-sm">
                  {{ item.text }}
                </p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="text-center mt-20">
        <v-btn
          class="text--white mt-5"
          size="x-large"
          dark
          color="primary"
          @click="goToQuote"
          data-test-id="get-quote-btn"
          >
          Get Covered Today
        </v-btn>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
export default defineComponent({
  name: 'SectionsLandingsHilltopSection4',
  setup() {
    const items = [
      {
        key: 'fast-and-easy',
        imgSrc: '~/assets/images/hilltop/tick-red.png',
        title: 'Cash Benefits',
        text: 'For expenses that may not be covered by your health insurance'
      },
      {
        key: 'affordable-protection',
        imgSrc: '@/assets/images/hilltop/tick-white.png',
        title: 'Affordable Protection',
        text: 'Only $20-$40 a month (18 to 70 years old)'
      },
      {
        key: 'paid-directly-to-you',
        imgSrc: '@/assets/images/hilltop/tick-white.png',
        title: 'Paid Directly To You',
        text: 'Regardless of your health insurance'
      }
    ]

    const goToQuote = () => {
      return navigateTo({ path: '/forms/quote/start' });
    }


    return { items, goToQuote };
  }
})
</script>