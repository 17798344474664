<template>
  <section id="powered-by">
    <div class="container my-15">
        <div class="row d-flex align-items-center">
          <!-- TEXT BLOCK -->
          <div class="col-md-12 order-last order-md-2">
            <div class="txt-block left-column wow fadeInRight text-center color--white">
              <!-- Title -->
              <div class="d-flex justify-content-center align-items-center text-center">
                <div class="s-20 mr-2">Powered by</div>
                <img src="~/assets/images/logo-emerge-dark.png" width="150px" alt="Emerge Logo">
              </div>
            </div>
          </div>
            <!-- END TEXT BLOCK -->
        </div>
        <!-- End row -->
    </div>
    <!-- End container -->
  </section>
</template>
