<template>
  <section class="py-50 ws-wrapper content-section division mt-1 pattern-01 bg--theme">
    <div class="container">
        <div class="bc-1-wrapper bg--fixed r-16">
          <div class="section-overlay">
            <div class="row d-flex align-items-center">
              <!-- TEXT BLOCK -->
              <div class="col-md-12 order-last order-md-2 color--white">
                <div class="txt-block left-column wow fadeInRight">
                  <!-- Title -->
                  <div class="section-title mb-70 color--white">
                    <h2 class="s-60 w-700">Fast & Easy to Enroll</h2>
                  </div>
                  
                  <!-- FEATURES-7 WRAPPER -->
                  <div class="fbox-wrapper text-center">
                    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                      <!-- FEATURE BOXES -->
                      <div class="col"
                        v-for="item in items" :key="item.key">
                        
                        <div class="fbox-7 fb-1 r-12 wow fadeInUp">
                          <!-- Icon -->
                          <div class="fbox-ico-wrap">
                            <div class="fbox-ico ico-50">
                              <div class="shape-ico" style="fill:#FFF">
                                <!-- Vector Icon -->
                                <span><v-icon color="primary" size="55">{{ item.icon }}</v-icon></span>
                                <!-- Shape -->
                                <svg
                                  viewBox="0 0 200 200"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z"
                                    transform="translate(100 100)"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <!-- End Icon -->
                          <!-- Text -->
                          <div class="fbox-txt mt-10">
                            <h6 class="s-20 w-700 color--white">{{ item.title }}</h6>
                            <p class="p-sm">
                              {{ item.text }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- END FEATURE BOXES -->
                    </div>
                    <!-- End row -->
                  </div>
                  <div class="text-center">
                    <v-btn
                      class="text--white mt-10"
                      size="x-large"
                      dark
                      color="accent"
                      @click="goToQuote"
                      data-test-id="get-quote-btn"
                      >
                      Get Covered Today
                    </v-btn>
                  </div>
                  <SectionsLandingsHilltopSectionPowered />
                </div>
              </div>
              <!-- END TEXT BLOCK -->
              <!-- IMAGE BLOCK -->
            </div>
            <!-- End row -->
          </div>
          <!-- End section overlay -->
        </div>
      <!-- End content wrapper -->
    </div>
    <!-- End container -->
  </section>
</template>
<script lang="ts">
export default defineComponent({
  name: 'SectionsLandingsHilltopSection6',
  setup() {
    const items = [
      {
        key: 'get-a-quote',
        icon: 'mdi-numeric-1',
        title: 'Get a Quote',
        text: 'Answer a few simple questions, get your rates and adjust the coverage to fit your needs.'
      },
      {
        key: 'apply-online',
        icon: 'mdi-numeric-2',
        title: 'Apply Online',
        text: 'Complete your application in minutes and get an instant decision.'
      },
      {
        key: 'gain-peace-of-mind',
        icon: 'mdi-numeric-3',
        title: 'Gain Peace of Mind',
        text: 'Know you & your family will have the money you need should an accident happen.'
      }
    ]
    const goToQuote = () => {
      return navigateTo({ path: '/forms/quote/start' });
    }

    return { items, goToQuote };
  }
})
</script>
<style lang="scss" scoped>
.fbox-ico-wrap {
  min-width: 50px;

  h1 {
    font-weight: 700;
  }
}
</style>
