<template>
  <section id="accident-happens" class="bg--theme-darken-2 pattern-03 bg--scroll banner-section">
      <div class="banner-overlay pt-80 pb-90">
          <div class="container">
              <!-- BANNER-6 WRAPPER -->
              <div class="banner-6-wrapper">
                  <div class="row justify-content-center">
                      <!-- BANNER-6 TEXT -->
                      <div class="col-md-10">
                          <div class="banner-6-txt text-center color--white">
                              <!-- Title -->
                              <h3 class="s-46 w-700 text-yellow-lighten-1">Accidents Happen</h3>
                              <!-- Subtitle -->
                              <h3 class="s-46 w-500">Protect Yourself With Accidental Injury Insurance</h3>
                              <!-- Text -->
                              <p class="p-xl o-85">Life can throw unexpected curveballs. With the average family deductible at $4,300, an accidental injury can cost you thousands before your health insurance kicks in. Emerge Accident Insurance bridges the gap, covering deductibles, co-pays and more when you need it most.</p>
                              <!-- Button -->
                              <a href="#" class="btn r-04 btn--theme hover--tra-white d-none" data-bs-toggle="modal" data-bs-target="#modal-3">Get srarted - it's free</a>
                              
                          </div>
                      </div>
                      <!-- END BANNER-6 TEXT -->
                  </div>
                  <!-- End row -->
              </div>
              <!-- END BANNER-6 WRAPPER -->
          </div>
          <!-- End container -->
      </div>
      <!-- End banner overlay -->
  </section>
</template>
