<template>
  <section id="why-emerge" class="pb-100 features-section  division">
    <hr class="divider" />
    <div class="text-center mt-100">
      <v-btn
        class="text--white"
        size="x-large"
        dark
        color="secondary"
        @click="goToLandingQuote"
        data-test-id="get-quote-btn"
        >
        Get Covered Now
      </v-btn>
    </div>
    <div class="container py-50">
      <div class="justify-content-center">
        <div class="section-title mb-70">
          <h2 class="s-60 w-700">Why Choose Emerge?</h2>
          <h2 class="s-30 w-500">Our simple process makes it easy for you be protected!</h2>
        </div>
      </div>
      <div class="fbox-wrapper text-center">
        <v-row>
          <v-col v-for="item in items" :key="item.key" cols="12" md="4">
            <div class="wow fadeInUp text-center color--white" style="visibility: visible; animation-name: fadeInUp;">
              <v-card elevation="15">
                <v-card-text class="bg--theme-darken-2 pattern-01">
                  <div class="d-flex flex-column align-items-center">
                    <v-img :src="item.imgSrc" :alt="item.title" width="110px" :key="`img-${item.key}`" />
                    <div style="max-width: 300px; min-height: 150px">
                      <h6 class="s-20 w-700 my-5">{{ item.title }}</h6>
                      <p class="p-sm" v-html="item.text"></p>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import eventBus from '~/utils/eventBus'

export default defineComponent({
  name: 'SectionsLandingsHilltopSection4',
  setup() {
    const items = [
      {
        key: 'cash-benefits',
        imgSrc: '/assets/images/emerge/tick-yellow.png',
        title: 'Cash Benefits',
        text: 'Paid directly to you even if you have health insurance'
      },
      {
        key: 'affordable-protection',
        imgSrc: '/assets/images/emerge/tick-yellow.png',
        title: 'Fast + Easy To Enroll',
        text: 'Sign up today online in 5 mins or less'
      },
      {
        key: 'paid-directly-to-you',
        imgSrc: '/assets/images/emerge/tick-yellow.png',
        title: 'Affordable Protection',
        text: 'As low as $18-$38 a month<br/>(18 to 70 years old)'
      }
    ]

    const goToQuote = () => {
      return navigateTo({ path: '/forms/quote/start' });
    }

    const goToLandingQuote = () => {
      eventBus.emit('focusQuoteFormFirstField')
      
    }

    


    return { items, goToQuote, goToLandingQuote };
  }
})
</script>